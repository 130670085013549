import { createStore, createLogger } from 'vuex'

import accountStore from 'KPackages/vuex-account/v4/store/index.js'
import servicesStore from 'KPackages/vuex-services/v4/store/index.js'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
      account: accountStore,
      services: servicesStore,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
